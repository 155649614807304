export interface UserFeaturesListRow {
  feature_name: string;
  quantity: number;
  updated_at: string;
  expires_at?: string;
}

export type KnownFeatures =
  | 'premium'
  | 'basic'
  | 'midi'
  | 'additional_universes'
  | 'additional_devices'
  | 'live_plot';
export type KnowFlags = 'flag_require_auth_after_days' | 'flag_organization_account';

// todo: move this to the right location?
export class UserFeatures {
  user_features_list: UserFeaturesListRow[] = [];
  user_features_map: Record<string, number> = {};

  _updated_at: Date = new Date();
  get updated_at(): string {
    return this._updated_at.toISOString();
  }
  set updated_at(value: Date | string) {
    if (value instanceof Date) {
      this._updated_at = value;
    } else {
      this._updated_at = new Date(value);
    }
    // console.log('set updated_at:', value, this._updated_at);
  }

  static fromList(list: UserFeaturesListRow[]): UserFeatures {
    const instance = new UserFeatures();
    instance.user_features_list = list;

    let updated_at: Date | undefined;
    list.forEach(row => {
      instance.user_features_map[row.feature_name] = row.quantity;
      if (!updated_at || new Date(row.updated_at) > updated_at) {
        updated_at = new Date(row.updated_at);
      }
    });
    instance.updated_at = updated_at ?? new Date('1970-01-01T00:00:00Z');
    console.info('-'.repeat(80));
    console.info('UserFeatures.fromList:', instance);
    console.info('-'.repeat(80));
    console.info('planName', instance.planName);
    console.info('isBasicUser', instance.isBasicUser);
    console.info('isPremiumUser', instance.isPremiumUser);
    console.info('isFreeUser', instance.isFreeUser);
    console.info('isOrganization', instance.isOrganization);
    console.info('hasMIDI', instance.hasMIDI);
    console.info('hasLivePlot', instance.hasLivePlot);
    console.info('maxUniverses', instance.maxUniverses);
    console.info('maxDevices', instance.maxDevices);
    console.info('-'.repeat(80));
    return instance;
  }

  hasFeature(feature: KnownFeatures): boolean {
    return this.user_features_map[feature] > 0;
  }

  getFeature(feature: KnownFeatures): number {
    return this.user_features_map[feature] ?? 0;
  }

  hasFlag(flag: KnowFlags): boolean {
    return this.user_features_map[flag] > 0;
  }

  getFlag(flag: KnowFlags): number {
    return this.user_features_map[flag] ?? 0;
  }

  get planName(): string {
    const withMidi = this.hasMIDI ? ' + MIDI' : '';
    const withLivePlot = this.hasLivePlot ? ' + LivePlot' : '';

    if (this.isPremiumUser) {
      return 'Premium' + withMidi + withLivePlot;
    }

    if (this.isBasicUser) {
      return 'Basic' + withMidi + withLivePlot;
    }

    // can Free have MIDI?
    return 'Free';
  }

  get isBasicUser(): boolean {
    return !this.isPremiumUser && this.hasFeature('basic');
  }

  get isPremiumUser(): boolean {
    return this.hasFeature('premium');
  }

  get isFreeUser(): boolean {
    return !this.isPremiumUser && !this.isBasicUser;
  }

  get isOrganization(): boolean {
    return this.hasFlag('flag_organization_account');
  }

  get hasMIDI(): boolean {
    return this.hasFeature('midi');
  }

  get hasLivePlot(): boolean {
    return this.hasFeature('live_plot');
  }

  get maxUniverses(): number {
    if (this.isPremiumUser) {
      return 256;
    }
    if (this.isBasicUser) {
      return 1 + this.getFeature('additional_universes');
    }
    return 0;
  }

  get maxDevices(): number {
    return 2 + this.getFeature('additional_devices');
  }

  get hasEverything(): boolean {
    return this.hasLivePlot && this.hasMIDI && (this.isPremiumUser || this.maxUniverses === 256);
  }

  toString() {
    return `UserFeatures<${JSON.stringify(this.user_features_map, null, 2)}, ${JSON.stringify(
      {
        updated_at: this.updated_at,
      },
      null,
      2
    )}>`;
  }

  isOlderThan(other: UserFeatures): boolean {
    // console.log('isOlderThan:', this._updated_at, other._updated_at);
    return !this.isNewerThan(other);
  }

  isNewerThan(other: UserFeatures): boolean {
    return this._updated_at.getTime() > other._updated_at.getTime();
  }
}

// this is a special case where we have no user features
export const NO_USER_FEATURES: UserFeatures = UserFeatures.fromList([
  {
    feature_name: 'NO_USER_FEATURES',
    quantity: 1,
    updated_at: '1970-01-01T00:00:00Z',
  },
]);
